jQuery(function($) {

    // $(document).ready(function(){
    //     $('#user_login').attr('placeholder', 'School Email Address');
    //     $('#user_pass').attr('placeholder', 'Your Password');
    // });
    
	setInterval(function(){ 
		if ($(".cky-consent-container").hasClass("cky-hide")) {
			$(".cky-overlay").addClass("cky-hide-force");
		}
		if ($(".cky-modal").hasClass("cky-modal-open")) {
			$(".cky-overlay").removeClass("cky-hide-force");
		}
	}, 100);

    $('#nav').html(function(index, text) {
        return text.replace(/\|/g, '');
    });

	if ($(".login-form-container")[0]) {
		if ($("#pw-checkbox")[0]) {
			document.getElementById("pw-checkbox").disabled = true;
			document.getElementById("pw-checkbox").remove();
		}
	}

	if ($("#login-swipe")[0]) {
		var swiper = new Swiper("#login-swipe", {
			// slidesPerView: 1,
			// effect: 'fade',
			// fadeEffect: { crossFade: true },
			loop: true,
			autoplay: {
				delay: 3400,
				pauseOnMouseEnter: true,
				disableOnInteraction: false,
			},
			speed: 500,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			}
		});

		swiper.on('slideChange', function(e) {
			$('#media').removeAttr('class');
			$('#media').addClass( "slide" + swiper.realIndex );
		});

	}

});



